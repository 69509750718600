import * as React from "react";

function ResponsivePicture({
  src,
  srcSet,
  avifSrcSet,
  webpSrcSet,
  sizes,
  media,
  alt,
  title,
  lazyload,
  width,
  height,
  className,
  crossOrigin,
  fetchPriority,
}) {
  return (
    <picture>
      {avifSrcSet && <source type="image/avif" srcSet={avifSrcSet} />}
      {webpSrcSet && <source type="image/webp" srcSet={webpSrcSet} />}
      <img
        src={src}
        srcSet={srcSet}
        width={width}
        height={height}
        alt={alt}
        className={className}
        loading={lazyload ? "lazy" : "auto"}
        media={media || undefined}
        title={title}
        crossOrigin={crossOrigin}
        fetchpriority={fetchPriority || undefined}
        sizes={sizes || undefined}
      />
    </picture>
  );
}

export default ResponsivePicture;
