import * as React from "react";
import { matchPath } from "react-router";

import ScrollToTop from "../ScrollToTop";

const DEFAULT_VALUE = {
  meta: {},
  id: 0,
  title: null,
  html: null,
  url: null,
  thumbnail: null,
  banners: null,
};

function Page({
  path,
  isReady,
  page,
  params,
  onGetPage,
  onSetUrl,
  onSetMeta,
  children,
  scrollToTop,
}) {
  React.useEffect(() => {
    const currentPath = page ? page.url : undefined;

    if (
      !(currentPath && matchPath(path, currentPath) && currentPath === path)
    ) {
      // content doesn't exist in store
      onGetPage({ path, ...params });
    } else {
      // content exists in store and user has navigated to a new URL
      onSetUrl(path);
      if (page) {
        onSetMeta(page.meta);
      }
    }
    // TODO:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, onSetUrl, onSetMeta, onGetPage, params]);

  if (typeof onGetPage === "undefined") {
    console.warn("<Page /> requires 'onGetPage' prop to be defined");
    return null;
  }

  return (
    <>
      {scrollToTop && <ScrollToTop />}
      {children({ isReady, page: page ?? DEFAULT_VALUE })}
    </>
  );
}

export default Page;
