import * as React from "react";
import Glider from "react-glider";

import ResponsivePicture from "../ResponsivePicture";
import styles from "./style.scss";

function Gallery({ data }) {
  const [isTrackLoaded, setIsTrackLoaded] = React.useState(false);

  return (
    <div className={styles.banner}>
      <Glider
        hasDots={data.length > 1}
        dots={`.${styles.dots}`}
        hasArrows={false}
        slidesToShow={1}
        duration={0.5}
        skipTrack
        onLoad={() => setIsTrackLoaded(true)}
      >
        <div className={`${isTrackLoaded ? "" : styles.track} glider-track`}>
          {data.map(({ id, alt, caption, images }, index) => (
            <div key={id} className={styles.content}>
              <div className={styles.image}>
                <ResponsivePicture
                  src={images.src}
                  srcSet={images.srcset}
                  alt={alt}
                  className={styles.picture}
                  lazyload={index > 0}
                  fetchPriority={index === 0 ? "high" : "low"}
                  important="true"
                  crossOrigin="anonymous"
                />
              </div>
              {caption && <span className={styles.caption}>{caption}</span>}
            </div>
          ))}
        </div>
      </Glider>
      <div className={styles.dots} />
    </div>
  );
}

export default Gallery;
