import * as React from "react";
import { Link } from "react-router-dom";

import styles from "./style.scss";

function FullWidthBanner({
  media,
  title,
  summary,
  url,
  lazyload,
  fetchPriority,
  reducedHeight,
  withOverlay,
}) {
  if (!(media && media.images)) {
    return null;
  }

  const bannerContent = (
    <div className={styles.content}>
      <div
        className={`${styles.image} ${
          reducedHeight ? styles.reducedHeight : ""
        } ${withOverlay ? styles.withOverlay : ""}`}
      >
        <picture>
          {media.images.sources &&
            media.images.sources.map((n) => (
              <source
                key={n.src}
                src={n.src}
                srcSet={n.srcset}
                sizes="100vw"
                media={n.media}
              />
            ))}
          <img
            src={media.images.src}
            srcSet={media.images.srcSet}
            alt={media.alt}
            className={styles.picture}
            loading={lazyload ? "lazy" : "auto"}
            decoding="async"
            crossOrigin="anonymous"
            fetchpriority={fetchPriority || undefined}
            sizes="100vw"
          />
        </picture>
      </div>
      {title && (
        <div className={styles.text}>
          <h2 className={styles.title}>{title}</h2>
          {summary && (
            <div
              className={styles.summary}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          )}
        </div>
      )}
    </div>
  );

  return (
    <section
      className={`${styles.section} ${
        reducedHeight ? styles.reducedHeight : ""
      }`}
    >
      {url ? <Link to={url}>{bannerContent}</Link> : bannerContent}
    </section>
  );
}

export default FullWidthBanner;
