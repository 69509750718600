import * as React from "react";
import { Link } from "react-router-dom";

import ResponsivePicture from "../ResponsivePicture";
import styles from "./style.scss";

const LABELS = {
  DATE_PREFIX: "Published",
  READ_MORE: "Read More",
};

function NewsArticle({ data, displayAsRow }) {
  const { thumbnail, category, url, title, date, summary } = data;

  return (
    <div className={`${styles.article} ${displayAsRow ? styles.row : ""}`}>
      {thumbnail && (
        <Link
          to={url}
          className={`${styles.thumbnail} ${
            displayAsRow ? styles.thumbnailRow : ""
          }`}
        >
          <ResponsivePicture
            src={thumbnail.src}
            srcSet={thumbnail.srcset}
            alt={thumbnail.alt}
            lazyload
            crossOrigin="anonymous"
          />
        </Link>
      )}
      <div
        className={`${styles.content} ${displayAsRow ? styles.contentRow : ""}`}
      >
        <div>
          {category && (
            <span className={styles.category}>{category.title}</span>
          )}
          <h3
            className={`${styles.title} ${displayAsRow ? styles.titleRow : ""}`}
          >
            <Link className={styles.link} to={url}>
              {title}
            </Link>
          </h3>
          {!displayAsRow && (
            <div
              className={styles.summary}
              dangerouslySetInnerHTML={{ __html: summary }}
            />
          )}
        </div>
        <div className={styles.footer}>
          <span
            className={styles.date}
          >{`${LABELS.DATE_PREFIX}: ${date}`}</span>
          <Link to={url} title={title} className={styles.readMore}>
            {LABELS.READ_MORE}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewsArticle;
