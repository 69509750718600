import * as React from "react";

import FILE_ICON from "./img/file.svg";
import styles from "./style.scss";

function Downloads({ downloads }) {
  return (
    <div className={styles.downloads}>
      {downloads.map((n) => (
        <div className={styles.downloadsSection} key={n.id}>
          <h3>{n.title}</h3>
          {n.links && (
            <ul className={styles.downloadLinks}>
              {n.links.map((m) => (
                <li key={m.id} className={styles.item}>
                  <a
                    href={m.url}
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer noopener"
                    download
                  >
                    <span className={styles.linkText}>
                      <span>{m.name}</span>
                    </span>
                    <img
                      src={FILE_ICON}
                      alt=""
                      loading="lazy"
                      fetchpriority="low"
                      width="24px"
                      height="24px"
                      className={styles.icon}
                    />
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}

export default Downloads;
