import * as React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.scss";

function TopNavigation({ navigation }) {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {navigation.map((n) => (
          <li key={n.id} className={styles.item}>
            {n.isExternal ? (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={n.url}
                className={styles.link}
                target={n.isNewWindow ? "_blank" : "_self"}
                rel={n.isNewWindow ? "noreferrer" : ""}
              >
                {n.title}
              </a>
            ) : (
              <NavLink
                to={n.url}
                className={({ isActive }) =>
                  `${isActive ? styles.active : undefined} ${styles.link}`
                }
              >
                {n.title}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default TopNavigation;
